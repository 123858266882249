.portfolio-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  position: relative;
  background-color: white;
  /* min-height: 100vh; */
}

.portfolio-image-gallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.portfolio-image-container {
  height: 200px;
  width: 100px;
}

.see-more-button {
  background-color: blueviolet !important;
}

.portfolio1 {
  background-color: pink;
  border-radius: 10px;
  margin: 10px;
}

.swiper-pagination-bullet-active {
background-color: pink !important;
} 
.swiper-button-next, .swiper-button-prev {
  color: pink !important;
}