#app {
  width: 100vw;
  flex: 1;
}

.shadow-portfolio {
  box-shadow: 0px 9px 15px 4px rgba(0, 0, 0, 0.05);
}

p {
  font-family: "Baskervville", cursive;
}
